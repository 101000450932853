/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '@bluheadless/ui/src/atoms/icon/icon'

const ArrowSelectCloseSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			d="M5 6l13 13m0 0H5.52M18 19V6.52"
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
			stroke="currentColor"
			strokeWidth={1.5}
		/>
	</svg>
))
ArrowSelectCloseSvg.displayName = 'ArrowSelectCloseSvg'

const ArrowSelectClose = forwardRef((props, ref) => <Icon component={ArrowSelectCloseSvg} ref={ref} {...props} />)
ArrowSelectClose.displayName = 'ArrowSelectClose'

ArrowSelectClose.defaultProps = {
	...Icon.defaultProps,
}
ArrowSelectClose.propTypes = {
	...Icon.propTypes,
}

export default ArrowSelectClose
export { ArrowSelectCloseSvg }
