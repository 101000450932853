import { forwardRef, Fragment } from 'react'
import InputLabel from '@mui/material/InputLabel'
import { FormControl, Select } from './dropdown.styled'
import ArrowSelectClose from '@/components/ui/atoms/icons/arrow-select-close'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import { cx } from '@emotion/css'

const DropdownUI = forwardRef(function DropdownUI(
	{
		className,
		disabled,
		formControlProps,
		helperText,
		label,
		labelProps,
		name,
		onChange,
		options,
		placeholder,
		required,
		value,
		multiple,
		isSticky,
		...props
	},
	ref
) {
	return (
		<FormControl disabled={disabled} required={required} error={props.error} {...formControlProps}>
			{label && (
				<InputLabel required={required} {...labelProps}>
					{label}
				</InputLabel>
			)}
			<Select
				className={cx(value ? 'selected' : null, className)}
				displayEmpty
				IconComponent={ArrowSelectClose}
				MenuProps={{
					elevation: 0,
					variant: 'menu',
					marginThreshold: 14,
					PopoverClasses: {
						paper: 'no-bg',
					},
					anchorOrigin: {
						vertical: isSticky ? 'top' : 'bottom',
						horizontal: 'left',
					},
					transformOrigin: {
						vertical: isSticky ? 'bottom' : 'top',
						horizontal: 'left',
					},
				}}
				name={name}
				onChange={onChange}
				ref={ref}
				value={value ?? (multiple ? [] : '')}
				multiple={multiple}
				renderValue={(selected) => {
					if (multiple) {
						return selected.length > 0
							? selected.map((single, i) => {
									const selectedOption = options.find(({ value }) => value === single)
									return (
										<Fragment key={selectedOption?.id ?? selectedOption?.value}>
											{selectedOption?.label}
											{selected.length - 1 === i ? '' : ', '}
										</Fragment>
									)
							  })
							: null
					} else {
						const selectedOption = options.find(({ value }) => value === selected)
						return selectedOption ? selectedOption.label : null
					}
				}}
				{...props}
			>
				{placeholder && (
					<MenuItem disabled value="" sx={{ display: 'none' }}>
						{placeholder}
					</MenuItem>
				)}
				{options.map(({ label, id, value, ...menuItemProps }) => {
					return (
						<MenuItem key={id ?? value} {...menuItemProps} value={id ?? value}>
							{label}
						</MenuItem>
					)
				})}
			</Select>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	)
})
export default DropdownUI
