import { array, bool, node, object, oneOfType, string } from 'prop-types'
import { forwardRef } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import DropdownUI from './dropdown-ui'
import { Select } from './dropdown.styled'

const Dropdown = forwardRef(function Dropdown(
	{
		disabled,
		formControlProps,
		helperText,
		label,
		labelProps,
		name,
		onChange,
		options,
		placeholder,
		required,
		value,
		isSticky,
		...props
	},
	ref
) {
	return (
		<DropdownUI
			disabled={disabled}
			formControlProps={formControlProps}
			helperText={helperText}
			label={label}
			labelProps={labelProps}
			name={name}
			onChange={onChange}
			options={options}
			placeholder={placeholder}
			required={required}
			value={value}
			ref={ref}
			isSticky={isSticky}
			{...props}
		/>
	)
})

const ControlledDropdown = ({
	name: nameProp,
	onChange: onChangeProp,
	onBlur: onBlurProp,
	helperText,
	defaultValue,
	disabled: _disabled,
	...props
}) => {
	const formContext = useFormContext()
	const isSubmitting = formContext?.formState?.isSubmitting

	const disabled = _disabled ?? isSubmitting

	const {
		field: { onChange, onBlur, name, value, ref },
		fieldState: { error },
	} = useController({ name: nameProp, defaultValue, rules: { required: props.required } })

	return (
		<DropdownUI
			error={!!error}
			helperText={error?.message || helperText}
			onChange={(...args) => {
				onChange(...args)
				if (onChangeProp) onChangeProp(...args)
			}}
			onBlur={(...args) => {
				onBlur(...args)
				if (onBlurProp) onBlurProp(...args)
			}}
			value={value}
			name={name}
			inputRef={ref}
			disabled={disabled}
			{...props}
		/>
	)
}

Dropdown.defaultProps = {
	...Select.defaultProps,
	disabled: false,
	formControlProps: {
		fullWidth: true,
	},
}

Dropdown.propTypes = {
	...Select.propTypes,
	disabled: bool,
	labelProps: object,
	options: array.isRequired,
	placeholder: oneOfType([string, object]),
	helperText: oneOfType([string, node]),
	required: bool,
	error: bool,
	name: string.isRequired,
}

ControlledDropdown.defaultProps = {
	...Dropdown.defaultProps,
	helperText: ' ',
}

ControlledDropdown.propTypes = {
	...Dropdown.propTypes,
}

export default Dropdown
export { Dropdown, ControlledDropdown }
